import React from 'react'
import { Icon as BaseIcon } from '../Icon'
import styled from '@emotion/styled'

const Icon = styled(BaseIcon)`
  color: ${({ theme }) => theme.orange};
  font-size: 1.1 em;
`

const Wrapper = styled.span`
  display: inline-block;
  font-family: BloggerSans-Bold;
  font-size: 0.8em;
  margin-bottom: 1.1em;
  color: ${({ theme }) => theme.black};
`

const getIcon = type => {
  switch (type) {
    case 'duration':
      return ['far', 'clock']
    case 'price':
      return 'coins'
    default:
      return ''
  }
}

const formatLabel = (type, label) => {
  switch (type) {
    case 'duration':
      return `+/- ${label} min.`
    case 'price':
      return `${label} EUR`
    default:
      return null
  }
}

const ContentItemIcon = ({ type, label, style }) => (
  <Wrapper style={style}>
    <Icon icon={getIcon(type)} /> {formatLabel(type, label)}
  </Wrapper>
)

export { ContentItemIcon }
