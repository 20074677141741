import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { BLOCKS, MARKS } from '@contentful/rich-text-types'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import SEO from '../components/seo'
import { Layout } from '../components/Layout'
import { theme } from '../components/Layout/theme'
import { PageHeader } from '../components/Header/PageHeader'
import { H1, SubTitle, H5 } from '../components/Title'
import { Container, Column, Row } from '../components/Grid'
import { Bold, Paragraph } from '../components/Text'
import styled from '@emotion/styled'
import { ContentItem } from '../components/ContentList/ContentItem'
import { ContentItemIcon } from '../components/ContentList/ContentItemIcon'
import { ButtonLink } from '../components/Button'

const ButtonLinkWrapper = styled.div`
  justify-content: center;
  display: flex;
  align-items: center;
`

const Treatment = styled.article`
  display: flex;
  margin-top: 1em;
  margin-bottom: 1em;
  align-items: center;
  justify-content: flex-start;

  &:last-child {
    margin-bottom: 0;
  }

  ${H5} {
    margin-top: 1em;
    padding: 0 1em;
  }

  img {
    display: flex;
    width: 70px;
    padding-right: 1rem;
  }

  ${Paragraph} {
    padding: 0 1em;
    margin-bottom: 0;
  }
`

const Disclaimer = styled.div`
  border-radius: 17.5px;
  font-family: BloggerSans-Light;
  padding: 1rem 2rem;
  line-height: 1.2;
  margin-bottom: 1rem;
  background-color: ${({ theme }) => theme.darkPink};
  color: ${({ theme }) => theme.white};
`

const SensitieveTherapiePage = () => {
  const data = useStaticQuery(graphql`
    fragment behandelingType on ContentfulBehandelingType {
      name
      behandeling {
        id
        title
        description
      }
    }
    {
      background: file(relativePath: { eq: "thera.jpg" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      pageContent: contentfulSection(slug: { eq: "sensitieve-therapie" }) {
        content {
          json
        }
      }

      holistic: contentfulMassage(title: { eq: "Holistische Massage" }) {
        id
        title
        description {
          description
        }
        price
        duration
      }
      display: contentfulBehandelingType(name: { eq: "Display" }) {
        ...behandelingType
        behandeling {
          icon {
            fixed(quality: 100, width: 100) {
              ...GatsbyContentfulFixed
            }
          }
        }
      }
    }
  `)

  const richTextOptions = {
    renderMark: {
      [MARKS.BOLD]: text => <Bold>{text}</Bold>,
    },
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => <Paragraph>{children}</Paragraph>,
    },
  }

  const pageContent = documentToReactComponents(
    data.pageContent.content.json,
    richTextOptions,
  )

  return (
    <Layout>
      <SEO title="Sensitieve Therapie" />
      <PageHeader background={data.background.childImageSharp.fluid}>
        <H1
          textAlign="center"
          color={theme.darkPink}
          style={{ marginBottom: '11px' }}>
          Coaching & therapie
        </H1>
        <SubTitle textAlign="center" color={theme.brown}>
          Luister naar je lichaam...
        </SubTitle>
      </PageHeader>

      <Container>
        <Row style={{ paddingTop: '60px', paddingBottom: '40px' }}>
          <Column display={{ md: 10, xs: 10 }} offset={{ md: 1, xs: 1 }}>
            {pageContent}
          </Column>
        </Row>
      </Container>
      <div
        style={{
          backgroundColor: '#FBF4F4',
        }}>
        <Container>
          <Row>
            <Column
              display={{ md: 10, xs: 12 }}
              offset={{ md: 1, xs: 0 }}
              style={{ paddingTop: '60px', paddingBottom: '25px' }}>
              <ContentItem
                title="Coaching"
                description={
                  <Paragraph>
                    Ik gids je doorheen een proces van bewustwording en inzicht
                    krijgen in jezelf, je patronen en je blokkades. Ik reik je
                    tools aan waarmee je aan de slag kan om tot een persoonlijke
                    groei te komen, beter kunt omgaan met je hooggevoeligheid en
                    positiever in het leven te staan.
                  </Paragraph>
                }
                icons={[
                  <ContentItemIcon
                    key={'coaching-duration'}
                    label={'60 - 90'}
                    type="duration"
                  />,
                  <ContentItemIcon
                    key={'coaching-price'}
                    label={'60 - 90'}
                    type="price"
                  />,
                ]}
              />

              <ContentItem
                title={'Therapie'}
                description={data.display.behandeling.map(b => (
                  <Treatment key={b.id}>
                    <img src={b.icon.fixed.src} alt="" width="90" />
                    <div>
                      <Paragraph small>
                        <strong>{b.title}</strong>: {b.description}
                      </Paragraph>
                    </div>
                  </Treatment>
                ))}
                icons={[
                  <ContentItemIcon
                    key={`therapy-duration`}
                    label={90}
                    type="duration"
                  />,
                  <ContentItemIcon
                    key={`therapy-price`}
                    label={90}
                    type="price"
                  />,
                ]}
              />

              <ContentItem
                title={'Coaching + energetische behandeling'}
                description={
                  <Paragraph>
                    Je kan bij mij terecht voor coaching in combinatie met een
                    energetische behandeling. Dit bevordert je herstel en heeft
                    een grotere boost op je fysieke, mentale en emotionele
                    welzijn.
                  </Paragraph>
                }
                icons={[
                  <ContentItemIcon
                    key={`therapy-duration`}
                    label={90}
                    type="duration"
                  />,
                  <ContentItemIcon
                    key={`therapy-price`}
                    label={130}
                    type="price"
                  />,
                ]}
              />
            </Column>
          </Row>
          <Row>
            <Column display={{ md: 10, xs: 12 }} offset={{ md: 1, xs: 0 }}>
              <Disclaimer>
                Energetische behandelingen zijn geen vervanging voor reguliere
                medische zorg. Raadpleeg bij fysieke of psychische klachten
                eerst een huisarts of medisch deskundige. Indien het naar mijn
                mening nodig is, zal ik u doorverwijzen naar de reguliere
                hulpverlening.
              </Disclaimer>
            </Column>
          </Row>
          <Row>
            <Column
              display={{ md: 10 }}
              offset={{ md: 1 }}
              style={{ padding: '30px 0' }}>
              <ButtonLinkWrapper>
                <ButtonLink wide large to="/maak-een-afspraak/">
                  Maak een afspraak
                </ButtonLink>
              </ButtonLinkWrapper>
            </Column>
          </Row>
        </Container>
      </div>
    </Layout>
  )
}
export default SensitieveTherapiePage
