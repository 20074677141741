import React from 'react'
import styled from '@emotion/styled'
import { H3 } from '../Title'
import { Link } from '@reach/router'

const Wrapper = styled(Link)`
  background: ${({ theme }) => theme.white};
  border-radius: 20px;
  display: block;
  margin-bottom: 35px;
  text-decoration: none;
`

const Icons = styled.p`
  span {
    margin-right: 20px;
  }
`

const Content = styled.div`
  padding: 20px 40px;
`

const ContentItem = ({ title, description, icons }) => (
  <Wrapper to="/maak-een-afspraak/">
    <Content>
      <H3>{title}</H3>
      {icons.length && <Icons>{icons}</Icons>}
      {description}
    </Content>
  </Wrapper>
)

export { ContentItem }
